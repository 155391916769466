import React, { useState, useEffect } from 'react';
import AppstorePage from '../components/AppstorePage';
import AppstorePageTitle from '../components/AppstorePageTitle';
import AppstoreCard from '../components/AppstoreCard';
import BuildYourOwnAppCard from '../components/BuildYourOwnAppCard';
import Carousel from '../components/Carousel';
import './Today.less';

const Today = () => {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);

  const SkeletonCard = () => (
    <div className="appstore-card skeleton-card">
      <div className="skeleton-image"></div>
      <div className="skeleton-content">
        <div className="skeleton-text"></div>
        <div className="skeleton-text"></div>
        <div className="skeleton-text"></div>
      </div>
    </div>
  );

  
    useEffect(() => {
      const fetchApps = async () => {
        try {
          setLoading(true);
          const res = await fetch('https://node.justbonfire.com/v1/intersend/interspace/apps', {
            headers: { 'x-api-key': 'jflksdf980UOHkjB545HFGVHJG57rJHBHJK5526KHJj' }
          });
          const data = await res.json();
          console.log(data);
          setApps(data);
        } catch (error) {
          console.error('Error fetching apps:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchApps();
    }, []);

  return (
    <AppstorePage noCollapsedNavbar>
      <AppstorePageTitle />
      <div className="appstore-card-grid">
        {loading ? (
          <>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </>
        ) : (
          <>
            {apps.map((app) => (
              <AppstoreCard
                key={app.id}
                image={app.banner}
                titleColor="#fff"
                titlePosition="bottom"
                titleLarge
                app={app}
              >
                <p><b>{app.name}</b> - {app.description}</p>
                <br />
                <p>{app.about}</p>
                <br />
                <h3>Features</h3>
                <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                  {app.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </ul>
                <br/>
                <Carousel screenshots={app.screenshots} />
              </AppstoreCard>
            ))}
        <BuildYourOwnAppCard>
          <p><b>Launch your app on Interspace</b></p>
          <br />
          <p>
            Leverage Intersend APIs, Iframe, and, soon, an SDK to obtain necessary permissions and connect to users' dedicated wallets. Intersend facilitates easy transactions for both Web3 and Web2 users, enabling them to send or receive any cryptocurrency seamlessly and onboard into the ecosystem efficiently.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a
              className="mt-6 inline-block rounded-lg border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
              href="https://intersend.mintlify.app/quickstart"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                window.open('https://intersend.mintlify.app/quickstart?utm_ref=interspace_landing', '_blank');
              }}
            >
              See Documentation
            </a>
          </div>
        </BuildYourOwnAppCard>
        </>
        )}
      </div>
    </AppstorePage>
  );
};

export default Today;
